import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { clearAllCartItems } from "../../../../actions/cart";
import { clearPaymentIntent } from "../../../../actions/payment";
import { Prompt } from "react-router-dom";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../CheckoutForm.scss";
import { isFieldExpired } from "../../../../utils/isFieldExpired";
import ContactFields from "../../CheckoutSteps/ContactFields";
import PaymentFields from "../../CheckoutSteps/PaymentFields";
import { generateStripeDataObject } from "./utils/generateStripeDataObject";
import { createOrderRequest } from "./utils/createOrderRequest";
import { trackAddPaymentInfo } from "../../../../tracking/trackAddPaymentInfo";

const MultiStepCheckoutForm = ({
  clientSecret,
  orderDetails,
  setOrderAttemptDetails,
  cartItems,
  activeStep,
  setActiveStep,
  validationSchema,
  setActiveValidationSchema,
  setCompletedStepCount,
  finalizeCheckoutProcess,
  paymentIntentId,
  discountCode,
  checkoutComplete,
  setCheckoutError,
}) => {
  // Initialize Hooks
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  // Payment States
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  // Core Logic States
  const testMode = useSelector((state) => state.admin.testMode);
  const orderStartDate = useSelector(
    (state) => state.menu.activeMenu.orderStartDate
  );
  const paymentIntentExpiry = useSelector(
    (state) => state.payment.paymentIntentExpiry
  );

  // Delivery date states
  const deliveryDateCount = useSelector(
    (state) => state.menu.activeMenu.deliveryDateCount
  );
  const deliveryDate1 = useSelector(
    (state) => state.menu.activeMenu.deliveryDate1
  );
  const deliveryDate2 = useSelector(
    (state) => state.menu.activeMenu.deliveryDate2
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      // Contact & Delivery Fields
      name: "",
      email: "",
      phone: "",
      deliveryAddressLine1: "",
      deliveryAddressLine2: "",
      deliveryCity: "",
      deliveryState: "AZ",
      deliveryZipCode: "",
      deliveryDate: deliveryDate1,
      includeDeliveryField: false,
      specialInstructions: "",
      // Payment Fields
      ccnumber: "",
      ccExpiry: "",
      ccCvc: "",
      useDifferentBillingAddress: "false",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingCity: "",
      billingState: "AZ",
      billingZipCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPaymentProcessing(true);
      try {
        // Try submitting form
        handleFullSubmit(values);
      } catch (err) {
        // Handle form submission error
        console.log(err);
      }
    },
  });

  const handleFullSubmit = async (formValues) => {
    trackAddPaymentInfo(cartItems, orderDetails.orderTotal.total, discountCode);
    // Check if Payment Intent is expired
    if (isFieldExpired(paymentIntentExpiry)) {
      console.log("Payment intent is expired");
      dispatch(clearPaymentIntent());
      return;
    }
    setPaymentProcessing(true);
    const payload = await stripe.confirmCardPayment(
      clientSecret,
      generateStripeDataObject(
        formValues,
        elements.getElement(CardNumberElement)
      )
    );
    if (payload.error) {
      setPaymentError(`Payment failed: ${payload.error.message}`);
      setPaymentProcessing(false);
      setPaymentSucceeded(false);
    } else {
      setPaymentError(null);
      setPaymentProcessing(false);
      setPaymentSucceeded(true);
      try {
        createOrderRequest(
          paymentIntentId,
          orderDetails.lineItems,
          orderDetails.orderTotal,
          formValues.includeDeliveryField,
          formValues.specialInstructions,
          formValues.email,
          discountCode,
          orderStartDate,
          formValues.deliveryDate,
          testMode
        ).then((res) => {
          finalizeCheckoutProcess({ ...res, ...formValues });
          dispatch(clearAllCartItems());
          dispatch(clearPaymentIntent());
        });
      } catch (error) {
        console.log(error);
        dispatch();
      }
    }
  };

  return (
    <form className="checkout-form" onSubmit={formik.handleSubmit}>
      <Prompt
        when={formik.dirty && !paymentSucceeded && paymentIntentId}
        message="Are you sure you want to leave this page? Any unsaved changes will be lost"
      />
      {activeStep === 1 ? (
        <ContactFields
          formik={formik}
          setActiveStep={setActiveStep}
          setActiveValidationSchema={setActiveValidationSchema}
          validationSchema={validationSchema}
          setCompletedStepCount={setCompletedStepCount}
          checkoutComplete={checkoutComplete}
          setCheckoutError={setCheckoutError}
          orderTotal={orderDetails.orderTotal.total}
          cartItems={cartItems}
          deliveryDateCount={deliveryDateCount}
          deliveryDate1={deliveryDate1}
          deliveryDate2={deliveryDate2}
        />
      ) : (
        <PaymentFields
          formik={formik}
          orderTotal={orderDetails.orderTotal}
          setOrderAttemptDetails={setOrderAttemptDetails}
          setActiveStep={setActiveStep}
          setActiveValidationSchema={setActiveValidationSchema}
          validationSchema={validationSchema}
          pending={paymentProcessing && !checkoutComplete}
          checkoutComplete={checkoutComplete}
          paymentError={paymentError}
          paymentSucceeded={paymentSucceeded}
        />
      )}
    </form>
  );
};

export default MultiStepCheckoutForm;
