import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CheckoutFieldSet from "./CheckoutFieldset";
import BrentoTextField from "../../UI/BrentoTextField/BrentoTextField";
import BrentoCheckbox from "../../UI/BrentoCheckbox/BrentoCheckbox";
import BrentoSelect from "../../UI/BrentoSelect/BrentoSelect";
import BrentoButton from "../../UI/BrentoButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Alert from "../../UI/Alert/Alert";
import { stateValueOptions } from "../CheckoutForm/stateValueOptions";
import { errorsInCurrentStep } from "./utils/errorsInCurrentStep";
import { allErrorsTouched } from "./utils/allErrorsTouched";
import DetectPaymentIntent from "../DetectPaymentIntent/DetectPaymentIntent";
import { validateZipCodeRequest } from "../../../utils/validateZipCodeRequest";
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "../../UI/Spinner/Spinner";
import { trackAddShippingInfo } from "../../../tracking/trackAddShippingInfo";

const ContactFields = ({
  formik,
  setActiveStep,
  setActiveValidationSchema,
  validationSchema,
  setCompletedStepCount,
  checkoutComplete,
  setCheckoutError,
  orderTotal,
  cartItems,
  cartDiscountCode,
  deliveryDateCount,
  deliveryDate1,
  deliveryDate2,
}) => {
  const [zipCodeLoading, setZipCodeLoading] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(false);

  let schemaKeys = Object.keys(validationSchema.fields);
  let errorKeys = Object.keys(formik.errors);
  let history = useHistory();

  const validateZipCodeField = async (value) => {
    setZipCodeLoading(true);
    try {
      let isZipCodeValid = await validateZipCodeRequest(value);
      if (isZipCodeValid.isValid) {
        setZipCodeLoading(false);
        setZipCodeValid(true);
        setZipCodeError(false);
      } else {
        setZipCodeLoading(false);
        setZipCodeValid(false);
        setZipCodeError(true);
        formik.setFieldError("deliveryZipCode", "Outside of delivery zone");
      }
    } catch (err) {
      setCheckoutError(err);
    }
  };

  const handleChangeZipCode = async (value) => {
    formik.setFieldValue("deliveryZipCode", `${value}`, true);
    if (value.length >= 5) {
      validateZipCodeField(value);
    }
  };

  const handleZipCodeBlur = async () => {
    formik.setFieldTouched("deliveryZipCode", true, true);
    if (!zipCodeValid && formik.values.deliveryZipCode.length > 0) {
      validateZipCodeField(formik.values.deliveryZipCode);
    }

    // setZipCodeLoading(true);
    // let isZipCodeValid = await validateZipCodeRequest(
    //   formik.values.deliveryZipCode
    // );
    // if (isZipCodeValid.isValid) {
    //   setZipCodeLoading(false);
    //   setZipCodeError(false);
    //   setZipCodeValid(true);
    // } else {
    //   setZipCodeLoading(false);
    //   setZipCodeError(isZipCodeValid.response.data.msg);
    //   setZipCodeValid(false);
    // }
  };

  const handleIncludeDeliveryField = () => {
    formik.setFieldValue(
      "includeDeliveryField",
      !formik.values.includeDeliveryField,
      false
    );
  };

  const handleNextStep = () => {
    formik.setTouched({
      name: true,
      email: true,
      phone: true,
      deliveryAddressLine1: true,
      deliveryCity: true,
      deliveryState: true,
      deliveryZipCode: true,
    });
    validateZipCodeField(formik.values.deliveryZipCode);
    formik.validateForm().then((errors) => {
      if (
        !errorsInCurrentStep(Object.keys(errors), schemaKeys) &&
        zipCodeValid
      ) {
        window.scrollTo(0, 0);
        setCompletedStepCount(1);
        setActiveStep(2);
        trackAddShippingInfo(cartItems, orderTotal, cartDiscountCode);
      } else {
        setCompletedStepCount(0);
      }
    });
  };

  useEffect(() => {
    setActiveValidationSchema("contact");
    if (errorsInCurrentStep(errorKeys, schemaKeys)) {
      setCompletedStepCount(0);
    }
  });

  useEffect(() => {
    if (formik.values.deliveryZipCode.length > 0) {
      validateZipCodeField(formik.values.deliveryZipCode);
    }
  }, []);

  return (
    <>
      <DetectPaymentIntent checkoutComplete={checkoutComplete} />
      <CheckoutFieldSet label="Contact information">
        <BrentoTextField
          label="Name"
          name="name"
          variant="outlined"
          fullWidth={true}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
        <BrentoTextField
          label="Email address"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          variant="outlined"
          fullWidth={true}
        />
        <BrentoTextField
          label="Phone number"
          type="tel"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          fullWidth={true}
          error={formik.touched.phone && formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </CheckoutFieldSet>
      <CheckoutFieldSet label="Delivery address">
        <BrentoTextField
          label="Address Line 1"
          variant="outlined"
          name="deliveryAddressLine1"
          value={formik.values.deliveryAddressLine1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth={true}
          error={
            formik.errors.deliveryAddressLine1 &&
            formik.touched.deliveryAddressLine1
          }
          helperText={
            formik.touched.deliveryAddressLine1 &&
            formik.errors.deliveryAddressLine1
          }
        />
        <BrentoTextField
          label="Address Line 2"
          name="deliveryAddressLine2"
          value={formik.values.deliveryAddressLine2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          fullWidth={true}
        />
        <div className="checkout-form__city-state-row">
          <BrentoTextField
            label="City"
            name="deliveryCity"
            value={formik.values.deliveryCity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            fullWidth={true}
            error={formik.touched.deliveryCity && formik.errors.deliveryCity}
            helperText={
              formik.touched.deliveryCity && formik.errors.deliveryCity
            }
          />
          <BrentoSelect
            label="State"
            name="deliveryState"
            value={formik.values.deliveryState}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={stateValueOptions}
            native={true}
            error={formik.errors.deliveryState && formik.errors.deliveryState}
            formHelperText={
              formik.errors.deliveryState && formik.errors.deliveryState
            }
          />
        </div>
        {formik.values.deliveryState !== "AZ" ? (
          <Alert
            header="Sorry, we're only in Arizona...for now!"
            content="At this time, Brento Box only operates in the Phoenix, Arizona area. Who knows what the future holds, but until then, we only serve customers in Arizona."
            type="error"
          />
        ) : null}

        <BrentoTextField
          label="ZIP Code"
          name="deliveryZipCode"
          value={formik.values.deliveryZipCode}
          onChange={(e) => handleChangeZipCode(e.target.value)}
          onBlur={() => handleZipCodeBlur()}
          variant="outlined"
          fullWidth={true}
          error={
            formik.touched.deliveryZipCode && formik.errors.deliveryZipCode
              ? true
              : false
          }
          helperText={
            formik.touched.deliveryZipCode && formik.errors.deliveryZipCode
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {zipCodeLoading ? (
                  <Spinner size="small" />
                ) : zipCodeValid ? (
                  <CheckIcon color="success" />
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        {zipCodeError ||
        (formik.touched.deliveryZipCode &&
          formik.errors.deliveryZipCode === "Outside of delivery zone") ? (
          <Alert
            header="Sorry, we don't deliver to your ZIP code"
            content={
              <p>
                Unfortunately, the delivery address that you provided is outside
                of our current delivery zone. Stay posted, as we may expand to
                your area in the future!
              </p>
            }
            type="error"
          />
        ) : null}

        <BrentoCheckbox
          label="Include special delivery instructions"
          checked={formik.values.includeDeliveryField}
          onChange={() => handleIncludeDeliveryField()}
          name="includeDeliveryField"
        />
        {formik.values.includeDeliveryField ? (
          <div className="checkout-form__special-instructions">
            <BrentoTextField
              fullWidth={true}
              multiline={true}
              rows={8}
              name="specialInstructions"
              variant="outlined"
              value={formik.values.specialInstructions}
              onChange={formik.handleChange}
              placeholder="Enter special instructions for your delivery driver, such as directions or gate codes."
            />
          </div>
        ) : null}
      </CheckoutFieldSet>
      <CheckoutFieldSet label="Delivery date">
        {deliveryDateCount === 2 ? (
          <FormControl>
            <FormLabel id="delivery-date-label" sx={{ pb: 2, mt: -2 }}>
              Select your delivery date
            </FormLabel>
            <RadioGroup
              aria-labelledby="delivery-date-label"
              value={formik.values.deliveryDate}
              onChange={formik.handleChange}
              name="deliveryDate"
            >
              <FormControlLabel
                value={deliveryDate1}
                control={<Radio />}
                label={moment(deliveryDate1).format("dddd, MMMM Do")}
              />
              <FormControlLabel
                value={deliveryDate2}
                control={<Radio />}
                label={moment(deliveryDate2).format("dddd, MMMM Do")}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          <p>{moment(deliveryDate1).format("dddd, MMMM Do")}</p>
        )}
      </CheckoutFieldSet>
      {errorsInCurrentStep(errorKeys, schemaKeys) &&
      allErrorsTouched(errorKeys, formik.touched) ? (
        <Alert
          header="We have found the following errors:"
          type="error"
          content={
            <ul>
              {Object.keys(formik.errors)
                .filter((key) => {
                  return formik.touched[key] === true;
                })
                .map((key) => {
                  return (
                    <li key={key} className="checkout-alert-error">
                      {formik.errors[key]}
                    </li>
                  );
                })}
            </ul>
          }
        />
      ) : null}
      <BrentoButton
        fullWidth={true}
        disabled={
          errorsInCurrentStep(errorKeys, schemaKeys) &&
          allErrorsTouched(errorKeys, formik.touched)
        }
        size="large"
        label="Next Step: Payment"
        onClick={() => handleNextStep()}
      >
        Next Step: Payment
      </BrentoButton>
      <BrentoButton
        variant="text"
        fullWidth={true}
        size="large"
        onClick={() => history.push("/cart")}
      >
        Return to Cart
      </BrentoButton>
    </>
  );
};

export default ContactFields;
