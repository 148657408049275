import axios from "axios";
import { combineMealName } from "../../../../../utils/combineMealName";

const prepareLineItems = (lineItems) => {
  return lineItems
    .filter((item) => 0 < item.qty)
    .map((item) => {
      let fullName = combineMealName(
        item.meal.mealName,
        item.meal.mealSubtitle
      );
      return {
        mealName: fullName,
        quantity: item.qty,
        mealId: item.meal._id,
        mealImageUrl: item.meal.mealImageUrl,
        price: item.meal.price,
      };
    });
};

export const createOrderRequest = async (
  paymentIntentId,
  lineItems,
  orderTotal,
  includeDeliveryField,
  specialInstructions,
  receiptEmail,
  discountCode,
  orderStartDate,
  deliveryDate,
  testMode
) => {
  let orderData = {
    paymentIntentId: paymentIntentId,
    lineItems: prepareLineItems(lineItems),
    orderTotal: orderTotal,
    specialInstructions: null,
    receiptEmail: receiptEmail,
    orderStartDate: orderStartDate,
    deliveryDate: deliveryDate,
  };

  if (includeDeliveryField) {
    orderData.specialInstructions = specialInstructions;
  }

  if (discountCode) {
    orderData.discountCode = discountCode;
  }

  let res = await axios({
    method: "post",
    url: testMode
      ? "/api/test-orders/create-order"
      : "/api/orders/create-order",
    data: orderData,
    headers: { "Content-Type": "application/json" },
  });

  return res.data;
};
