import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import "./ExpressCheckout.scss";
import { useHistory } from "react-router-dom";
import { clearPaymentIntent } from "../../../actions/payment";
import {
  clearAllCartItems,
  clearCartDiscountCode,
} from "../../../actions/cart";
import { setOrderConfirmationData } from "../../../actions/orders";
import { paymentIntentRequest } from "../../../Pages/Payment/Checkout/utils/paymentIntentRequest";
import { createShippingOptions } from "./utils/createShippingOptions";
import { returnShippingOption } from "./utils/returnShippingOption";
import { isZipValid } from "./utils/isZipValid";
import { createOrderRequest } from "../CheckoutForm/MultiStepCheckout/utils/createOrderRequest";
import { trackBeginCheckout } from "../../../tracking/trackBeginCheckout";
import { trackAddPaymentInfo } from "../../../tracking/trackAddPaymentInfo";
import { trackAddShippingInfo } from "../../../tracking/trackAddShippingInfo";
import { trackPurchase } from "../../../tracking/trackPurchase";

const ExpressCheckout = ({
  total,
  subtotal,
  processing,
  disabled,
  cartItems,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const history = useHistory();
  const orderConfirmationData = useSelector(
    (state) => state.orders.orderConfirmationData
  );
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [expressCheckoutComplete, setExpressCheckoutComplete] = useState(false);
  const [expressMethod, setExpressMethod] = useState("Express Checkout");
  const testMode = useSelector((state) => state.admin.testMode);
  const cartDiscountCode = useSelector((state) => state.cart.cartDiscountCode);
  const orderStartDate = useSelector(
    (state) => state.menu.activeMenu.orderStartDate
  );

  // Get delivery date options
  const deliveryDateCount = useSelector(
    (state) => state.menu.activeMenu.deliveryDateCount
  );
  const deliveryDate1 = useSelector(
    (state) => state.menu.activeMenu.deliveryDate1
  );
  const deliveryDate2 = useSelector(
    (state) => state.menu.activeMenu.deliveryDate2
  );

  const handleTrackExpressCheckout = () => {
    console.log();
    trackBeginCheckout(cartItems, total * 100, cartDiscountCode, expressMethod);
  };

  const calculateTotalWithDiscount = (orderTotal, discountCode) => {
    if (discountCode.discountCategory === "gift-card") {
      if (discountCode.currentBalance >= orderTotal) {
        return 50;
      } else {
        return orderTotal - discountCode.currentBalance;
      }
    }
  };

  useEffect(() => {
    let totalAfterDiscount;

    setPaymentRequest(null);
    if (stripe && subtotal && processing) {
      let subtotalCents = subtotal * 100;
      let taxesCents = processing * 100;
      let orderTotal = Math.trunc(subtotalCents + taxesCents + 500);
      console.log(orderTotal);
      if (cartDiscountCode) {
        totalAfterDiscount = calculateTotalWithDiscount(
          orderTotal,
          cartDiscountCode
        );
      }
      // Create Stripe paymentRequest instance
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Order Total",
          amount: cartDiscountCode ? totalAfterDiscount : orderTotal,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        disableWallets: ["link"],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          if (result.applePay === true) {
            setExpressMethod("Apple Pay");
          } else if (result.googlePay === true) {
            setExpressMethod("Google Pay");
          }
        }
      });

      // Event handler for when user inputs their shipping address in browser payment interface
      pr.on("shippingaddresschange", function (ev) {
        trackAddShippingInfo(cartItems, total * 100, cartDiscountCode);
        let shippingOptions = createShippingOptions(
          deliveryDateCount,
          deliveryDate1,
          deliveryDate2
        );
        // Ensure shipping address is within delivery zone
        if (!isZipValid(ev.shippingAddress.postalCode)) {
          console.log("Invalid shipping address");
          ev.updateWith({
            status: "invalid_shipping_address",
          });
        } else {
          console.log("Successfully updated shipping address");
          ev.updateWith({
            status: "success",
            shippingOptions: shippingOptions,
          });
        }
      });

      // Event handler for when user inputs their payment method. Used to complete the transaction.
      pr.on("paymentmethod", async (ev) => {
        trackAddPaymentInfo(cartItems, total * 100, cartDiscountCode);
        if (!isZipValid(ev.shippingAddress.postalCode)) {
          ev.updateWith({
            status: "invalid_shipping_address",
          });
        } else {
          // Create paymentIntent on the server
          const res = await paymentIntentRequest(
            cartItems,
            null,
            cartDiscountCode,
            testMode
          );
          let deliveryDate =
            ev.shippingOption.id === "deliveryDate1"
              ? deliveryDate1
              : deliveryDate2;
          const stripeClientSecret = res.stripeClientSecret;
          // Confirm the paymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              stripeClientSecret,
              {
                payment_method: ev.paymentMethod.id,
                shipping: {
                  address: {
                    city: ev.shippingAddress.city,
                    state: ev.shippingAddress.region,
                    line1: ev.shippingAddress.addressLine[0],
                    line2: ev.shippingAddress.addressLine[1] || null,
                    postal_code: ev.shippingAddress.postalCode,
                  },
                  name: ev.payerName,
                  phone: ev.payerPhone,
                },
              },
              { handleActions: false }
            );

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            console.log(confirmError);
            ev.complete("fail");
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete("success");
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                stripeClientSecret
              );
              if (error) {
                ev.complete("fail");
                console.log(error);
                return;
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
                console.log("EV:");
                console.log(ev);
                console.log("RES:");
                console.log(res);
                createOrderRequest(
                  res.stripePaymentIntentId,
                  res.orderDetails.lineItems,
                  res.orderDetails.orderTotal,
                  false,
                  null,
                  ev.payerEmail,
                  cartDiscountCode,
                  orderStartDate,
                  deliveryDate,
                  testMode
                ).then((res) => {
                  trackPurchase(
                    res.lineItems,
                    res.orderTotal,
                    res.discountCode,
                    res.paymentIntentId,
                    expressMethod
                  );
                  dispatch(clearCartDiscountCode());
                  dispatch(clearAllCartItems());
                  dispatch(clearPaymentIntent());
                  dispatch(setOrderConfirmationData(res));
                  setExpressCheckoutComplete(true);
                  history.push("/payment/express/thank-you");
                });
              }
            } else {
              console.log("EV:");
              console.log(ev);
              console.log("RES:");
              console.log(res);
              // The payment has succeeded.
              createOrderRequest(
                res.stripePaymentIntentId,
                res.orderDetails.lineItems,
                res.orderDetails.orderTotal,
                false,
                null,
                ev.payerEmail,
                cartDiscountCode,
                orderStartDate,
                deliveryDate,
                testMode
              ).then((res) => {
                trackPurchase(
                  res.lineItems,
                  res.orderTotal,
                  res.discountCode,
                  res.paymentIntentId,
                  expressMethod
                );
                dispatch(clearCartDiscountCode());
                dispatch(clearAllCartItems());
                dispatch(clearPaymentIntent());
                dispatch(setOrderConfirmationData(res));
                setExpressCheckoutComplete(true);
                history.push("/payment/express/thank-you");
              });
            }
          }
        }
      });
    }
  }, [
    stripe,
    testMode,
    cartItems,
    processing,
    cartDiscountCode,
    expressMethod,
  ]);

  return (
    <>
      {paymentRequest && !disabled && (
        <div className="express-checkout">
          <PaymentRequestButtonElement
            onClick={() => handleTrackExpressCheckout()}
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  height: "48px",
                  borderRadius: "2px",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ExpressCheckout;
