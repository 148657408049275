import React from "react";
import Container from "../../Layout/Container";
import Section from "../../Layout/Section/Section";
import "./HowItWorks.scss";
import Step1 from "../../../img/Illustrations/Illustrations_Step1.svg";
import Step2 from "../../../img/Illustrations/Illustrations_Step2-Updated.svg";
import Step3 from "../../../img/Illustrations/Illustrations_Step3.svg";
import Step4 from "../../../img/Illustrations/Illustrations_Step4.svg";

const HowItWorks = () => {
  return (
    <Section sectionColor="white" sectionTitle="How It Works">
      <Container>
        <div className="how-it-works">
          <div className="how-it-works__step">
            <img
              src={Step1}
              alt="Illustration of a delicious healthy meal"
              className="how-it-works__illo"
            />
            <div className="how-it-works__content">
              <h4 className="how-it-works__step-name">Check The Menu</h4>
              <p className="how-it-works__description">
                {/* A new menu is released every week on Thursday morning. The menu
                can be found on this site, on instagram, as well as sent out via
                text. */}
                Check out which tasty meals we're featuring this week. A new
                menu is released to this site each week on Tuesday morning.
              </p>
            </div>
          </div>
          <div className="how-it-works__step">
            <img
              src={Step2}
              alt="Illustration of a shopping cart adding a meal"
              className="how-it-works__illo"
            />
            <div className="how-it-works__content">
              <h4 className="how-it-works__step-name">Place Your Order</h4>
              <p className="how-it-works__description">
                {/* We now accept online orders! Add available meals from the menu
                to your online cart and complete our online checkout process
                before noon on Friday of that week. */}
                Add the meals you would like to your cart and complete the
                online checkout by 9:00 AM on Friday. All orders must meet a $40
                minimum.
              </p>
            </div>
          </div>
          <div className="how-it-works__step">
            <img
              src={Step3}
              alt="Illustration of a bag of meals waiting to be picked up or delivered"
              className="how-it-works__illo"
            />
            <div className="how-it-works__content">
              <h4 className="how-it-works__step-name">Confirm Delivery</h4>
              <p className="how-it-works__description">
                Your meals are made fresh to order that weekend. On the morning
                of your scheduled delivery date, we'll confirm your delivery
                window.
              </p>
            </div>
          </div>
          <div className="how-it-works__step">
            <img
              src={Step4}
              alt="Illustration of a microwave heating up a tasty meal"
              className="how-it-works__illo"
            />
            <div className="how-it-works__content">
              <h4 className="how-it-works__step-name">Heat, Eat, Repeat</h4>
              <p className="how-it-works__description">
                2-3 minutes in the microwave, or heat up on a skillet and you're
                ready to enjoy! Be sure to check back the following week for a
                new menu!
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default HowItWorks;
