import moment from "moment";

const convertToPhoenixTime = (date) => {
  return moment(date).set({ h: 7, m: 0 }).format("YYYY-MM-DDTHH:mm");
};

export const convertDeliveryDates = (values) => {
  let newValues = { ...values };
  if (newValues.deliveryDateCount === "2") {
    newValues.deliveryDate1 = convertToPhoenixTime(values.deliveryDate1);
    newValues.deliveryDate2 = convertToPhoenixTime(values.deliveryDate2);
  } else {
    newValues.deliveryDate1 = convertToPhoenixTime(values.deliveryDate1);
    newValues.deliveryDate2 = convertToPhoenixTime(values.deliveryDate1);
  }
  return newValues;
};
