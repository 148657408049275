import moment from "moment";

export const createShippingOptions = (
  deliveryDateCount,
  deliveryDate1,
  deliveryDate2
) => {
  let results = [];
  if (deliveryDateCount === 2) {
    results.splice(0, 0, {
      id: "deliveryDate1",
      label: moment(deliveryDate1).format("dddd, MMMM Do yyyy"),
      amount: 500,
    });
    results.splice(1, 0, {
      id: "deliveryDate2",
      label: moment(deliveryDate2).format("dddd, MMMM Do yyyy"),
      amount: 500,
    });
  } else {
    results = [
      {
        id: "deliveryDate1",
        label: moment(deliveryDate1).format("dddd, MMMM Do yyyy"),
        amount: 500,
      },
    ];
  }
  return results;
};
