import moment from "moment";
export const handleAutoConfigureDate = (
  dayIso,
  hourOfDay,
  minuteOfDay,
  dateType = "window"
) => {
  // ISO Reference: ------ //
  // Sunday = 0
  // Monday = 1
  // Tuesday = 2
  // Wednesday = 3
  // Thursday = 4
  // Friday = 5
  // Saturday = 6
  const today = moment().isoWeekday();
  if (dateType === "window") {
    if (today < dayIso) {
      // then just give me this week's instance of that day
      return moment()
        .isoWeekday(dayIso)
        .set({ h: hourOfDay, m: minuteOfDay })
        .format("YYYY-MM-DDTHH:mm");
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment()
        .add(1, "weeks")
        .isoWeekday(dayIso)
        .set({ h: hourOfDay, m: minuteOfDay })
        .format("YYYY-MM-DDTHH:mm");
    }
  } else {
    return moment().add(2, "weeks").isoWeekday(dayIso).format("YYYY-MM-DD");
  }
};
