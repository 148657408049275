import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../../UI/Spinner/Spinner";
import { DataGrid } from "@mui/x-data-grid";
import "./OrderList.scss";

const OrderList = ({ orderData, activeMeals, paginatedOrders }) => {
  const [pageSize, setPageSize] = useState(25);
  const paginatedOrdersLoading = useSelector(
    (state) => state.orders.paginatedOrdersLoading
  );

  return (
    <div className="order-list" style={{ height: 450 }}>
      {paginatedOrdersLoading && orderData.length <= 0 ? (
        <Spinner size="large" />
      ) : (
        <DataGrid
          columns={generateColumns(orderData.mealBreakdown)}
          rows={paginatedOrders}
          getRowId={(row) => row._id}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      )}
    </div>
  );
};

// const columns = [
//   { field: "orderId", headerName: "Order Number", width: 150, sortable: false },
//   {
//     field: "name",
//     headerName: "Customer",
//     width: 150,
//     sortable: false,
//   },
//   {
//     field: "orderTotal",
//     headerName: "Total",
//     width: 150,
//     type: "string",
//     sortable: false,
//     valueGetter: (params) => {
//       var valueInDollars = params.row.orderTotal / 100;
//       valueInDollars = valueInDollars.toLocaleString("en-US", {
//         style: "currency",
//         currency: "USD",
//       });
//       return valueInDollars;
//     },
//   },
// ];

const generateColumns = (mealBreakdown) => {
  const initialColumns = [
    {
      field: "orderId",
      headerName: "Order Number",
      width: 150,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Customer",
      width: 125,
      sortable: false,
    },
    {
      field: "orderTotal",
      headerName: "Total",
      width: 100,
      type: "string",
      sortable: false,
      valueGetter: (params) => {
        var valueInDollars = params.row.orderTotal / 100;
        valueInDollars = valueInDollars.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
        return valueInDollars;
      },
    },
    {
      field: "deliveryDate",
      headerName: "Delivery date",
      width: 120,
      sortable: true,
      renderCell: (params) => {
        if (params.row.deliveryDate && params.row.deliveryDate !== null) {
          return <p>{moment(params.row.deliveryDate).format("MM/DD/yyyy")}</p>;
        }
      },
    },
    {
      field: "discountCode",
      headerName: "Code",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.discountCode && params.row.discountCode !== null) {
          return (
            <p className="discount-code">
              {params.row.discountCode.discountCode}
            </p>
          );
        }
      },
    },
  ];
  const mealColumns = Object.keys(mealBreakdown);
  mealColumns.forEach((mealColumn) => {
    let newMealColumn = {
      field: mealColumn,
      headerName: mealBreakdown[mealColumn].mealName,
      type: "number",
      width: 150,
      sortable: false,
      valueGetter: (params) => {
        // console.log(params.row.lineItems);
        let lineItem = params.row.lineItems.filter(
          (lineItem) => lineItem.mealName === mealBreakdown[mealColumn].mealName
        );
        if (lineItem.length > 0) {
          return lineItem[0].quantity;
        } else {
          return 0;
        }
      },
    };
    initialColumns.push(newMealColumn);
  });
  return initialColumns;
};

export default OrderList;
